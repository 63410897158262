import { scroll } from "./modules/header/scroll.js";
import { burger } from "./modules/header/burger.js";
import { custom_menu } from "./modules/header/custom_menu.js";
import { anim } from "./modules/home/anim.js";
import { piling_solutions } from "./modules/application/single/piling_solutions.js";
import { tab_systems } from "./modules/application/single/tab_systems.js";
import { gallery } from "./modules/contacts/gallery.js";
import { gallerys } from "./modules/application/gallerys.js";
import { modal } from "./modules/contacts/modal.js";
import { phone } from "./modules/contacts/phone.js";
import { custom_slider } from "./modules/product/custom_slider.js";
import { accordion } from "./modules/product/single/accordion.js";
import { faq } from "./modules/contacts/faq.js";
// import { more } from "./modules/product/single/more.js";
import { tabs } from "./modules/product/single/tabs.js";
import { gallery_slider } from "./modules/product/single/gallery_slider.js";
import { selects } from "./modules/product/single/selects.js";
import { blog } from "./modules/home/blog.js";
import { tab } from "./modules/home/tab.js";
import { product } from "./modules/home/product.js";
import { popup } from "./modules/popup/popup.js";
import { search } from "./modules/search/search.js";
import { menu } from "./modules/footer/menu.js";
import { photo } from "./modules/home/photo.js";
import { split_text } from "./modules/about/split_text.js";
import { sliders } from "./modules/taxonomy/sliders.js";
import { copy } from "./modules/post/copy.js";
import { features } from "./modules/taxonomy/features.js";
import { number } from "./modules/careers/number.js";
import { position } from "./modules/careers/position.js";
import { products } from "./modules/patents/products.js";
import { product_bg } from "./modules/home/product_bg.js";
import { applications } from "./modules/about/applications.js";
document.addEventListener("DOMContentLoaded", function (event) {
  copy();
  scroll();
  burger();
  anim();
  tabs();
  accordion();
  search();
  gallerys();
  product();
  modal();
  custom_menu();

  tab();
  gallery_slider();
  selects();
  blog();
  sliders();
  gallery();
  menu();
  // more();
  popup();
  faq();
  split_text();
  phone();
  number();
  position();
  products();
  photo();
  product_bg();
  applications();
  piling_solutions();
  tab_systems();
  features();
  custom_slider();
});
